<template>
  <v-layout column justify-center align-center class="mt-4 pt-2">
    <VueCompareImage
      class="hidden-md-and-down"
      hover
      :style="{ minWidth: '1300px' }"
      :sliderLineWidth="sliderLine"
      :handleSize="hSize"
      :leftImage="leftImage"
      :rightImage="rightImage"
      :sliderPositionPercentage="sliderPosition"
    />
    <VueCompareImage
      class="hidden-lg-and-up"
      hover
      :style="{ maxWidth: '400px' }"
      :sliderLineWidth="sliderLine"
      :handleSize="hSize"
      :leftImage="leftImage2"
      :rightImage="rightImage2"
      :sliderPositionPercentage="sliderPosition"
    />

    <br>

    <vue-typer class="headline" :repeat="0" text="Danyal Javed"></vue-typer>
    <vue-typer
      :text="text1"
      :repeat="Infinity"
      :shuffle="false"
      initial-action="erasing"
      :pre-type-delay="70"
      :type-delay="70"
      :pre-erase-delay="1980"
      :erase-delay="150"
      erase-style="select-back"
      :erase-on-complete="false"
      caret-animation="smooth"
    ></vue-typer>
    <br>
    <v-flex xs12 sm6 md4 lg4 xl4>
      <v-card flat color="transparent" max-width="500">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              <span>About</span>
              <span class="green--text">Me</span>
            </h3>
            <div>
              <p style="text-align: justify;">
                Hello! I’m Danyal Javed. I'm a computer scientist who
                is passionate about
                <span
                  class="green--text font-weight-bold"
                >VUE</span>, creating incredible client encounters, battling for effortlessness over multifaceted nature, and continually learning. At the point when I'm not coding or making videos, you'll see me bridging Europe.
              </p>
            </div>
          </div>
        </v-card-title>

        <v-card-actions class="hidden-sm-and-down justify-center">
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            fab
            dark
            outline
            color="green"
            :href="icon.href"
            target="_blank"
          >
            <v-icon dark>{{icon.icon}}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-actions class="hidden-md-and-up justify-center">
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            small
            fab
            dark
            outline
            color="green"
            :href="icon.href"
            target="_blank"
          >
            <v-icon dark>{{icon.icon}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { VueTyper } from 'vue-typer'
import VueCompareImage from 'vue-compare-image'

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: "%s ← Danyal's Space",
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: "Danyal Javed's Portfolio" },
      { charset: 'utf-8' },
      { property: 'og:title', content: "Danyal' Space" },
      { property: 'og:site_name', content: "Danyal' Space" },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://mrcasm.com' },
      {
        property: 'og:image',
        content: 'https://i.imgur.com/Dcz2PGx.jpg'
      },
      { property: 'og:description', content: "Danyal Javed's Portfolio" }
    ]
  },
  components: {
    'vue-typer': VueTyper,
    VueCompareImage
  },
  data () {
    if (JSON.parse(localStorage.getItem('nightMode')) === true) {
      return {
        icons: [
          { href: 'https://github.com/danyal0', icon: 'fab fa-github' },
          //          {
          //            href: 'https://stackoverflow.com/',
          //            icon: 'fab fa-stack-overflow'
          //          },
          { href: 'https://twitter.com/thenyal', icon: 'fab fa-twitter' },
          {
            href: 'https://www.linkedin.com/in/dj0',
            icon: 'fab fa-linkedin-in'
          },
          //          {
          //            href: 'https://www.xing.com/',
          //            icon: 'fab fa-xing'
          //          },
          {
            href: 'https://www.facebook.com/0dani',
            icon: 'fab fa-facebook-f'
          },
          { href: 'https://www.instagram.com/0danyal', icon: 'fab fa-instagram' }
        ],
        text1: ['Back-End Developer', 'Web Developer', 'Web Designer'],
        leftImage: '/1d.jpg',
        rightImage: '/2d.jpg',
        leftImage2: '/3d.jpg',
        rightImage2: '/4d.jpg',
        sliderLine: 0,
        hSize: 0,
        sliderPosition: 0.5
      }
    }
    return {
      icons: [
        { href: 'https://github.com/danyal0', icon: 'fab fa-github' },
        //        {
        //          href: 'https://stackoverflow.com/',
        //          icon: 'fab fa-stack-overflow'
        //        },
        { href: 'https://twitter.com/thenyal', icon: 'fab fa-twitter' },
        {
          href: 'https://www.linkedin.com/in/dj0',
          icon: 'fab fa-linkedin-in'
        },
        //        {
        //          href: 'https://www.xing.com/',
        //          icon: 'fab fa-xing'
        //        },
        {
          href: 'https://www.facebook.com/0dani',
          icon: 'fab fa-facebook-f'
        },
        { href: 'https://www.instagram.com/0danyal', icon: 'fab fa-instagram' }
      ],
      text1: ['Back-End Developer', 'Web Developer', 'Web Designer'],
      leftImage: '/1.jpg',
      rightImage: '/2.jpg',
      leftImage2: '/3.jpg',
      rightImage2: '/4.jpg',
      sliderLine: 0,
      hSize: 0,
      sliderPosition: 0.5
    }
  },
  methods: {
    forceRerender () {
      this.$router.go()
    }
  }
}
</script>
<style>
@keyframes rocking {
  0%,
  100% {
    transform: rotateZ(-10deg);
  }

  50% {
    transform: rotateZ(10deg);
  }
}

.vue-typer {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.vue-typer .custom.char.typed {
  color: green;
}
.vue-typer .custom.char.selected {
  color: #e91e63;
}

.vue-typer .custom.caret {
  animation: rocking 1s ease-in-out 0s infinite;
}
.vue-typer .custom.caret.typing {
  background-color: green;
}
.vue-typer .custom.caret.selecting {
  display: inline-block;
  background-color: #e91e63;
}
</style>
